import {
  COUNT_MASTER_FAILED,
  COUNT_MASTER_SUCCEED,
  COUNT_MASTER_PROCESSING,
  FETCH_MASTER_FAILED,
  FETCH_MASTER_SUCCEED,
  FETCH_MASTER_PROCESSING,
  GET_MASTER_FAILED,
  GET_MASTER_SUCCEED,
  GET_MASTER_PROCESSING,
  SAVE_MASTER_FAILED,
  SAVE_MASTER_SUCCEED,
  SAVE_MASTER_PROCESSING,
  DELETE_MASTER_FAILED,
  DELETE_MASTER_SUCCEED,
  DELETE_MASTER_PROCESSING,
} from '../../constants'
import Parse from '../parse'

export const master = {
  count(searchText) {
    return async dispatch => {
      dispatch({
        type: COUNT_MASTER_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend('AppParameters'))
      searchText = searchText || ''
      if (searchText !== '') {
        query.matches('GardenCompassSubscriptionLink', searchText.trim(), 'i')
      }
      try {
        const total = await query.count({ useMasterKey: true })
        dispatch({
          type: COUNT_MASTER_SUCCEED,
          payload: {
            total: total,
          },
        })
      } catch (error) {
        dispatch({
          type: COUNT_MASTER_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
  list(page, rowsPerPage, searchText) {
    return async dispatch => {
      dispatch({
        type: FETCH_MASTER_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend('AppParameters'))
      searchText = searchText || ''
      if (searchText !== '') {
        query.matches('GardenCompassSubscriptionLink', searchText.trim(), 'i')
      }
      query.limit(rowsPerPage)
      query.skip(page * rowsPerPage)
      query.descending('createdAt')
      try {
        const results = await query.find()
        var list = []
        list = results.map(r => {
          return {
            [r.id]: {
              sharedCredits: r.get('sharedCredits'),
              signupCredits: r.get('signupCredits'),
              signupCreditsForSharedUser: r.get('signupCreditsForSharedUser'),
              GardenCompassSubscriptionLink: r.get(
                'GardenCompassSubscriptionLink'
              ),
              GardenDesignLink: r.get('GardenDesignLink'),
              careCalendarLink: r.get('CareCalendarLink'),
            },
          }
        })
        dispatch({
          type: FETCH_MASTER_SUCCEED,
          payload: {
            list: list,
          },
        })
      } catch (error) {
        dispatch({
          type: FETCH_MASTER_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
  get(id) {
    return async dispatch => {
      dispatch({
        type: GET_MASTER_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend('AppParameters'))
      query.include('categoryType')
      query.include('masterProduct')
      try {
        const master = await query.get(id)
        dispatch({
          type: GET_MASTER_SUCCEED,
          payload: {
            record: master.toJSON(),
          },
        })
      } catch (error) {
        dispatch({
          type: GET_MASTER_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
  save(values) {
    return async dispatch => {
      dispatch({
        type: SAVE_MASTER_PROCESSING,
      })
      let masterModel = new Parse.Object.extend('AppParameters')
      let master = new masterModel()
      if (
        values.object_id !== undefined &&
        values.object_id !== null &&
        values.object_id !== ''
      ) {
        master.id = values.object_id
      }
      for (var k in values) {
        if (k !== 'object_id') {
          master.set(k, values[k])
        }
      }
      try {
        await master.save(null)
        dispatch({
          type: SAVE_MASTER_SUCCEED,
        })
      } catch (error) {
        dispatch({
          type: SAVE_MASTER_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
  delete(id) {
    return async dispatch => {
      dispatch({
        type: DELETE_MASTER_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend('AppParameters'))
      try {
        const plant = await query.get(id, {})
        await plant.destroy()
        dispatch({
          type: DELETE_MASTER_SUCCEED,
        })
      } catch (error) {
        dispatch({
          type: DELETE_MASTER_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
}
