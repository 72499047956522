import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Edit as EditIcon,
  Add as AddIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons'
import { Button } from '@material-ui/core'
import SweetAlert from 'sweetalert2-react'
import debounce from 'lodash.debounce'

import { expert } from '../../actions'
import CustomTable from '../Table/CustomTable'

class ExpertList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      rowsPerPage: 10,
      fields: [
        {
          id: 'firstName',
          label: 'First Name',
        },
        {
          id: 'lastName',
          label: 'Last Name',
        },
        // { id: 'regionName', numeric: false, disablePadding: false, label: 'Region Name' },
        {
          id: 'snapp_id_count',
          label: "Snapp Id's Count",
        },
        { id: 'email', label: 'Email' },
        { id: 'job', label: 'Job' },
        {
          id: 'phoneNumber',
          label: 'Phone Number',
        },
      ],
    }
  }
  componentDidMount() {
    this.props.dispatch(expert.count())
    this._getExpert()
  }
  _getExpert() {
    this.props.dispatch(expert.list(this.state.page, this.state.rowsPerPage))
  }
  handleChangePage = page => {
    let that = this
    this.setState({ page: page }, () => {
      that._getExpert()
    })
  }
  handleSearch = debounce(query => {
    this.props.dispatch(expert.count(query))
    this.props.dispatch(
      expert.list(this.state.page, this.state.rowsPerPage, query)
    )
  }, 1000)
  handleChangeRowsPerPage = rowsPerPage => {
    let that = this
    this.setState({ rowsPerPage: rowsPerPage }, () => {
      that._getExpert()
    })
  }
  handleDeleteAction = id => {
    this.props.dispatch(expert.delete(id))
  }
  hideAlert(event) {
    this.props.dispatch(expert.hideAlert())
  }
  render() {
    const { errorMessage, deleted } = this.props
    return (
      <div>
        <div style={{ textAlign: 'right' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => this.props.history.push('/expert/add')}
          >
            <AddIcon /> Add New
          </Button>
        </div>
        <CustomTable
          title="expert"
          total={this.props.total}
          history={this.props.history}
          tableHeaderColor="primary"
          tableHead={this.state.fields}
          page={this.state.page}
          rowsPerPage={this.state.rowsPerPage}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          handleSearch={this.handleSearch}
          handleDelete={this.handleDeleteAction}
          data={this.props.list}
          editPath="/expert/edit/"
          actions={[
            {
              label: 'edit',
              icon: <EditIcon />,
              path: '/expert/edit/',
              has_id: true,
              color: 'primary',
            },
            {
              label: 'delete',
              icon: <DeleteIcon />,
              has_id: true,
              color: 'secondary',
            },
          ]}
        />
        <SweetAlert
          type={errorMessage ? 'error' : 'success'}
          show={deleted || Boolean(errorMessage)}
          title={errorMessage ? 'Error' : 'Notice'}
          text={errorMessage ? errorMessage : 'Deleted'}
          onConfirm={this.hideAlert.bind(this)}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  total: state.expert.total,
  list: state.expert.list,
  deleted: state.expert.deleted,
  errorMessage: state.expert.errorMessage,
})

export default connect(mapStateToProps)(ExpertList)
