// import DashboardPage from '../components/Home'
import Genus from '../components/Genus/GenusList'
import GenusForm from '../components/Genus/GenusForm'
import Product from '../components/Product/ProductList'
import ProductForm from '../components/Product/ProductForm'
import SnappList from '../components/Snapp/SnappList'
import SnappForm from '../components/Snapp/SnappForm'
import PlantList from '../components/Plant/PlantList'
import PlantForm from '../components/Plant/PlantForm'
import Barcode from '../components/Barcode/BarcodeList'
import BarcodeForm from '../components/Barcode/BarcodeForm'
import BarcodeImport from '../components/Barcode/BarcodeImport'
import MatchBarcode from '../components/Barcode/MatchBarcode'
import Region from '../components/Region/RegionList'
import RegionForm from '../components/Region/RegionForm'
import Category from '../components/Category/CategoryList'
import CategoryForm from '../components/Category/CategoryForm'
import Retailer from '../components/Retailer/RetailerList'
import RetailerForm from '../components/Retailer/RetailerForm'
import RetailersGroup from '../components/RetailersGroup/RetailersGroupList'
import RetailersGroupForm from '../components/RetailersGroup/RetailersGroupForm'
import Expert from '../components/Expert/ExpertList'
import ExpertForm from '../components/Expert/ExpertForm'
// import User from '../components/User/UserList'
// import UserForm from '../components/User/UserForm'
import Master from '../components/Master/Master'
import MasterForm from '../components/Master/MasterForm'
import GenusCareInfo from '../components/GenusCareInfo/GenusCareInfoList'
import GenusCareInfoForm from '../components/GenusCareInfo/GenusCareInfoForm'
import CategoryCareInfo from '../components/CategoryCareInfo/CategoryCareInfoList'
import CategoryCareInfoForm from '../components/CategoryCareInfo/CategoryCareInfoForm'
// import Report from '../components/Report/Report'
// import Marketing from '../components/Marketing/Marketing'
import {
  CameraAlt,
  LocalFlorist,
  ViewWeek,
  Star,
  Public,
  SortByAlpha,
  LocalOffer,
  Today,
  Person,
  People,
  Accessibility,
  // Face,
  Settings,
  // Notifications,
  // Info,
} from '@material-ui/icons'

const appRoutes = [
  {
    path: '/snapps',
    sidebarName: 'snapps',
    navbarName: 'snapps',
    icon: CameraAlt,
    component: SnappList,
    routes: [
      {
        action: 'add',
        path: '/snapps/add',
        component: SnappForm,
      },
      {
        action: 'edit',
        path: '/snapps/edit/:id',
        component: SnappForm,
      },
      {
        action: 'delete',
        path: '/snapps/delete/:id',
        component: SnappList,
      },
    ],
  },
  {
    path: '/plant',
    sidebarName: 'plant',
    navbarName: 'plant',
    icon: LocalFlorist,
    component: PlantList,
    routes: [
      {
        action: 'add',
        path: '/plant/add',
        component: PlantForm,
      },
      {
        action: 'edit',
        path: '/plant/edit/:id',
        component: PlantForm,
      },
      {
        action: 'delete',
        path: '/plant/delete/:id',
        component: PlantList,
      },
    ],
  },
  {
    path: '/barcode',
    sidebarName: 'barcode',
    navbarName: 'barcode',
    icon: ViewWeek,
    component: Barcode,
    routes: [
      {
        action: 'add',
        path: '/barcode/add',
        component: BarcodeForm,
      },
      {
        action: 'edit',
        path: '/barcode/edit/:id',
        component: BarcodeForm,
      },
      {
        action: 'delete',
        path: '/barcode/delete/:id',
        component: Barcode,
      },
      {
        action: 'import',
        path: '/barcode/import',
        component: BarcodeImport,
      },
      {
        action: 'match',
        path: '/barcode/match',
        component: MatchBarcode,
      },
      {
        action: 'match-index',
        path: '/barcode/match/:index',
        component: MatchBarcode,
      },
    ],
  },
  {
    path: '/product',
    sidebarName: 'product',
    navbarName: 'product',
    icon: Star,
    component: Product,
    routes: [
      {
        action: 'add',
        path: '/product/add',
        component: ProductForm,
      },
      {
        action: 'edit',
        path: '/product/edit/:id',
        component: ProductForm,
      },
      {
        action: 'delete',
        path: '/product/delete/:id',
        component: Product,
      },
    ],
    breakLine: true,
  },
  {
    path: '/regions',
    sidebarName: 'regions',
    navbarName: 'regions',
    icon: Public,
    component: Region,
    routes: [
      {
        action: 'add',
        path: '/regions/add',
        component: RegionForm,
      },
      {
        action: 'edit',
        path: '/regions/edit/:id',
        component: RegionForm,
      },
      {
        action: 'delete',
        path: '/regions/delete/:id',
        component: Region,
      },
    ],
  },
  {
    path: '/genus',
    sidebarName: 'genus',
    navbarName: 'genus',
    icon: SortByAlpha,
    component: Genus,
    routes: [
      {
        action: 'add',
        path: '/genus/add',
        component: GenusForm,
      },
      {
        action: 'edit',
        path: '/genus/edit/:id',
        component: GenusForm,
      },
      {
        action: 'delete',
        path: '/genus/delete/:id',
        component: Genus,
      },
    ],
  },
  {
    path: '/categories',
    sidebarName: 'categories',
    navbarName: 'categories',
    icon: LocalOffer,
    component: Category,
    routes: [
      {
        action: 'add',
        path: '/categories/add',
        component: CategoryForm,
      },
      {
        action: 'edit',
        path: '/categories/edit/:id',
        component: CategoryForm,
      },
      {
        action: 'delete',
        path: '/categories/delete/:id',
        component: Category,
      },
    ],
  },
  {
    path: '#care_calendar',
    sidebarName: 'care calendar',
    navbarName: 'care calendar',
    icon: Today,
    // component: DashboardPage,
    children: [
      {
        path: '/categoryCareInfo',
        sidebarName: 'category',
        navbarName: 'category',
        icon: LocalOffer,
        component: CategoryCareInfo,
        routes: [
          {
            action: 'add',
            path: '/categoryCareInfo/add',
            component: CategoryCareInfoForm,
          },
          {
            action: 'edit',
            path: '/categoryCareInfo/edit/:id',
            component: CategoryCareInfoForm,
          },
          {
            action: 'delete',
            path: '/categoryCareInfo/delete/:id',
            component: CategoryCareInfo,
          },
        ],
      },
      {
        path: '/genusCareInfo',
        sidebarName: 'genus',
        navbarName: 'genus',
        icon: SortByAlpha,
        component: GenusCareInfo,
        routes: [
          {
            action: 'add',
            path: '/genusCareInfo/add',
            component: GenusCareInfoForm,
          },
          {
            action: 'edit',
            path: '/genusCareInfo/edit/:id',
            component: GenusCareInfoForm,
          },
          {
            action: 'delete',
            path: '/genusCareInfo/delete/:id',
            component: GenusCareInfo,
          },
        ],
      },
    ],
    breakLine: true,
  },
  {
    path: '/retailers',
    sidebarName: 'retailers',
    navbarName: 'retailer',
    icon: Person,
    component: Retailer,
    routes: [
      {
        action: 'add',
        path: '/retailers/add',
        component: RetailerForm,
      },
      {
        action: 'edit',
        path: '/retailers/edit/:id',
        component: RetailerForm,
      },
      {
        action: 'delete',
        path: '/retailers/delete/:id',
        component: Retailer,
      },
    ],
  },
  {
    path: '/retailersGroups',
    sidebarName: 'retailer groups',
    navbarName: 'retailer groups',
    icon: People,
    component: RetailersGroup,
    routes: [
      {
        action: 'add',
        path: '/retailersGroups/add',
        component: RetailersGroupForm,
      },
      {
        action: 'edit',
        path: '/retailersGroups/edit/:id',
        component: RetailersGroupForm,
      },
      {
        action: 'delete',
        path: '/retailersGroups/delete/:id',
        component: RetailersGroup,
      },
    ],
    breakLine: true,
  },
  {
    path: '/expert',
    sidebarName: 'expert',
    navbarName: 'expert',
    icon: Accessibility,
    component: Expert,
    routes: [
      {
        action: 'add',
        path: '/expert/add',
        component: ExpertForm,
      },
      {
        action: 'edit',
        path: '/expert/edit/:id',
        component: ExpertForm,
      },
      {
        action: 'delete',
        path: '/expert/delete/:id',
        component: Expert,
      },
    ],
  },
  // {
  //   path: '/user',
  //   sidebarName: 'user',
  //   navbarName: 'user',
  //   icon: Face,
  //   component: User,
  //   routes: [
  //     {
  //       action: 'add',
  //       path: '/user/add',
  //       component: UserForm,
  //     },
  //     {
  //       action: 'edit',
  //       path: '/user/edit/:id',
  //       component: UserForm,
  //     },
  //     {
  //       action: 'delete',
  //       path: '/user/delete/:id',
  //       component: User,
  //     },
  //   ],
  //   breakLine: true,
  // },
  {
    path: '/master',
    sidebarName: 'master',
    navbarName: 'master',
    icon: Settings,
    component: Master,
    routes: [
      {
        action: 'add',
        path: '/master/add',
        component: MasterForm,
      },
      {
        action: 'edit',
        path: '/master/edit/:id',
        component: MasterForm,
      },
      {
        action: 'delete',
        path: '/master/delete/:id',
        component: Master,
      },
    ],
  },
  // {
  //   path: '/notifications',
  //   sidebarName: 'notifications',
  //   navbarName: 'notifications',
  //   icon: Notifications,
  //   component: DashboardPage,
  // },
  // {
  //   path: '/reports',
  //   sidebarName: 'reports',
  //   navbarName: 'reports',
  //   icon: Info,
  //   component: Report,
  // },
  // {
  //   path: '/marketing',
  //   sidebarName: 'marketing',
  //   navbarName: 'marketing',
  //   icon: Message,
  //   component: Marketing,
  // },
  {
    redirect: true,
    path: '/',
    to: '/dashboard',
    navbarName: 'Redirect',
  },
]

export default appRoutes
