import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  Edit as EditIcon,
  Add as AddIcon,
  Delete as DeleteIcon,
  Close,
} from '@material-ui/icons'
import {
  Button,
  Snackbar,
  SnackbarContent,
  IconButton,
} from '@material-ui/core'
import debounce from 'lodash.debounce'

import { retailersGroup as retailersGroupActions } from '../../actions'
import CustomTable from '../Table/CustomTable'

class RetailersGroup extends Component {
  state = {
    page: 0,
    rowsPerPage: 10,
    fields: [
      {
        id: 'logo',
        label: 'Logo',
        type: 'image',
      },
      { id: 'name', label: 'Name' },
      {
        id: 'regions',
        label: 'Regions',
      },
      {
        id: 'isDefault',
        label: 'Is default',
      },
      {
        id: 'isPartner',
        label: 'Is partner',
      },
      {
        id: 'stripeSubscription',
        label: 'Stripe subscription',
      },
    ],
  }

  static propTypes = {
    count: PropTypes.func.isRequired,
    fetch: PropTypes.func.isRequired,
    delete: PropTypes.func.isRequired,
    hideAlert: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    deleted: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    total: PropTypes.number.isRequired,
    list: PropTypes.array.isRequired,
  }

  async componentDidMount() {
    await this.props.count()
    this._getRetailersGroup()
  }
  async _getRetailersGroup() {
    await this.props.fetch(this.state.page, this.state.rowsPerPage)
  }
  handleChangePage = page => {
    let that = this
    this.setState({ page: page }, () => {
      that._getRetailersGroup()
    })
  }
  handleSearch = debounce(query => {
    this.props.count(query)
    this.props.fetch(this.state.page, this.state.rowsPerPage, query)
  }, 1000)
  handleChangeRowsPerPage = rowsPerPage => {
    let that = this
    this.setState({ rowsPerPage: rowsPerPage }, () => {
      that._getRetailersGroup()
    })
  }
  handleDeleteAction = id => {
    this.props.delete(id)
  }
  hideAlert = () => {
    this.props.hideAlert()
  }
  render() {
    const { total, history, list, errorMessage, deleted } = this.props
    return (
      <div>
        <div style={{ textAlign: 'right' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push('/retailersGroups/add')}
          >
            <AddIcon /> Add New
          </Button>
        </div>
        <CustomTable
          title="Retailers Groups"
          total={total}
          history={history}
          tableHeaderColor="primary"
          tableHead={this.state.fields}
          page={this.state.page}
          rowsPerPage={this.state.rowsPerPage}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          handleSearch={this.handleSearch}
          handleDelete={this.handleDeleteAction}
          data={list}
          editPath="/retailersGroups/edit/"
          actions={[
            {
              label: 'edit',
              icon: <EditIcon />,
              path: '/retailersGroups/edit/',
              has_id: true,
              color: 'primary',
            },
            {
              label: 'delete',
              icon: <DeleteIcon />,
              has_id: true,
              color: 'secondary',
            },
          ]}
        />
        <Snackbar
          open={deleted || Boolean(errorMessage)}
          autoHideDuration={6000}
          onClose={this.handleClose}
        >
          <SnackbarContent
            aria-describedby="client-snackbar"
            message={errorMessage ? errorMessage : 'Deleted'}
            action={[
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={this.hideAlert}
              >
                <Close />
              </IconButton>,
            ]}
          />
        </Snackbar>
      </div>
    )
  }
}

const mapStateToProps = ({ retailersGroup }) => ({
  ...retailersGroup,
})

const mapDispatchToProps = { ...retailersGroupActions }

export default connect(mapStateToProps, mapDispatchToProps)(RetailersGroup)
