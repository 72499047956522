import { genus as genusAPI } from '../api'
import { HIDE_GENUS_ALERT } from '../constants/genus.constants'
export const genus = {
  fetch(page, rowsPerPage, query) {
    return dispatch => {
      dispatch(genusAPI.list(page, rowsPerPage, query))
    }
  },
  get(id) {
    return dispatch => {
      dispatch(genusAPI.get(id))
    }
  },
  getCategories() {
    return dispatch => {
      dispatch(genusAPI.getCategories())
    }
  },
  getProducts(query) {
    return dispatch => {
      dispatch(genusAPI.getProducts(query))
    }
  },
  count(query) {
    return dispatch => {
      dispatch(genusAPI.count(query))
    }
  },
  save(values) {
    return dispatch => {
      dispatch(genusAPI.save(values))
    }
  },
  delete(id) {
    return dispatch => {
      dispatch(genusAPI.delete(id))
    }
  },
  hideAlert() {
    return dispatch => {
      dispatch({
        type: HIDE_GENUS_ALERT,
      })
    }
  },
}
