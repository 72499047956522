import React from 'react'
import PropTypes from 'prop-types'
import { Menu } from '@material-ui/icons'
import {
  AppBar,
  Toolbar,
  IconButton,
  Hidden,
  Button,
  withStyles
} from '@material-ui/core'
import cx from 'classnames'

import { user } from '../../actions'
import headerStyle from '../../variables/styles/headerStyle.jsx'

import HeaderLinks from './HeaderLinks'

function Header({ ...props }) {
  function makeBrand() {
    var name = 'SmartPlant'
    props.routes.map((prop, key) => {
      if (prop.path === props.location.pathname) {
        name = prop.navbarName
      }
      return null
    })
    return name
  }
  function handleDrawerToggle() {
    props.dispatch(user.toggleSidebar())
  }
  const { classes, color } = props
  const appBarClasses = cx({
    [' ' + classes[color]]: color
  })
  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <div className={classes.flex}>
          {/* Here we create navbar brand, based on route name */}
          <Button href="#" className={classes.title}>
            {makeBrand()}
          </Button>
        </div>
        <Hidden smDown implementation="css">
          <HeaderLinks />
        </Hidden>
        <Hidden mdUp>
          <IconButton
            className={classes.appResponsive}
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  )
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger']),
  dispatch: PropTypes.func.isRequired
}

export default withStyles(headerStyle)(Header)
