import { user as userAPI } from '../api'
import { HIDE_USER_ALERT, TOGGLE_SIDEBAR } from '../constants/user.constants'
export const user = {
  list(page, rowsPerPage, query) {
    return dispatch => {
      dispatch(userAPI.list(page, rowsPerPage, query))
    }
  },
  get(id) {
    return dispatch => {
      dispatch(userAPI.get(id))
    }
  },
  getRegions(query) {
    return dispatch => {
      dispatch(userAPI.getRegions(query))
    }
  },
  count(query) {
    return dispatch => {
      dispatch(userAPI.count(query))
    }
  },
  save(values) {
    return dispatch => {
      dispatch(userAPI.save(values))
    }
  },
  delete(id) {
    return dispatch => {
      dispatch(userAPI.delete(id))
    }
  },
  hideAlert() {
    return dispatch => {
      dispatch({
        type: HIDE_USER_ALERT,
      })
    }
  },
  toggleSidebar() {
    return dispatch => {
      dispatch({
        type: TOGGLE_SIDEBAR,
      })
    }
  },
}
