import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import AsyncSelect from 'react-select/lib/Async'
import { Button, CircularProgress, Fade, withStyles } from '@material-ui/core'

import RegularCard from '../Cards/RegularCard.js'
import { barcode } from '../../actions'
import {
  plant as plantAPI,
  retailersGroup as retailersGroupAPI,
} from '../../api'
import { overlay, loadingSpinner } from '../../variables/styles'
import CustomTextField from '../Input/CustomTextField.js'
import formPropTypes from '../../constants/formPropTypes'
import CustomSnackbar from 'components/CustomSnackbar/index.js'

export const fields = [
  'id',
  'barcode',
  'plant',
  'retailersGroup',
  'retailersGroups',
]

const onSubmit = (values, dispatch, props) => {
  values.id = props.initialValues.id
  props.save(values)
}

class BarcodeForm extends Component {
  static propTypes = {
    ...formPropTypes,
    classes: PropTypes.object.isRequired,
    plantDescription: PropTypes.string,
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.dispatch(barcode.get(this.props.match.params.id))
    }
  }

  getPlants = query => plantAPI.select(query)

  getRetailersGroup = query => retailersGroupAPI.select(query)

  hideAlert = () => {
    this.props.hideAlert()
    this.props.history.push('/barcode')
  }
  render() {
    const {
      record,
      classes,
      handleSubmit,
      submitSucceeded,
      submitting,
      plantDescription,
      error,
      pristine,
      fetching,
      history,
    } = this.props
    const title =
      (this.props.match.params.id ? 'Edit ' : 'Add new') +
      (record !== null ? record.barcode : '')
    return (
      <div>
        <CustomSnackbar
          variant="error"
          open={Boolean(error)}
          message={error && error.message}
          handleClose={() => this.handleCloseError()}
        />
        <CustomSnackbar
          variant="info"
          open={Boolean(submitting)}
          message="Saving"
        />
        <CustomSnackbar
          variant="success"
          open={Boolean(submitSucceeded)}
          message="Saved"
          onClose={() => {}}
        />
        <Fade in={this.props.fetching}>
          <div
            className={classes.overlay}
            style={{ zIndex: this.props.fetching ? 10 : 0 }}
          >
            <CircularProgress className={classes.loadingSpinner} />
          </div>
        </Fade>
        <RegularCard
          cardTitle={title}
          content={
            <form onSubmit={handleSubmit}>
              <div>
                <Field
                  name="barcode"
                  component={CustomTextField}
                  label="Barcode"
                />
              </div>
              <div>
                <label>Plant</label>
                <Field
                  name="plant"
                  label="Plant"
                  component={props => (
                    <AsyncSelect
                      {...props.input}
                      loadOptions={this.getPlants}
                    />
                  )}
                />
                <div>
                  {plantDescription ? (
                    <div style={{ marginTop: '10px', fontSize: '12px' }}>
                      <strong>Plant Description: </strong>
                      {plantDescription.description}
                    </div>
                  ) : null}
                </div>
              </div>

              <div>
                <label>Retailers Groups</label>
                <Field
                  name="retailersGroups"
                  label="Retailers Groups"
                  component={props => (
                    <AsyncSelect
                      {...props.input}
                      multi
                      loadOptions={this.getRetailersGroup}
                      placeholder="Select retailers groups"
                    />
                  )}
                />
              </div>
            </form>
          }
          footer={
            <>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={fetching || pristine}
              >
                Submit
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => history.push('/barcode')}
              >
                Back to list
              </Button>
            </>
          }
        />
      </div>
    )
  }
}

const mapStateToProps = ({ barcode }) => ({
  record: barcode.record,
  retailersGroups: barcode.retailersGroups,
  fetching: barcode.fetching,
  fetched: barcode.fetched,
  saving: barcode.saving,
  saved: barcode.saved,
  errorMessage: barcode.errorMessage,
  fields,
  plantDescription: barcode.plantDescription,
  initialValues: { ...barcode.record },
})

const mapDispatchToProps = { ...barcode }

const styles = theme => ({
  overlay,
  loadingSpinner,
})

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form: 'barcode',
      onSubmit,
      enableReinitialize: true,
      keepDirtyOnReinitialize: true,
    })(BarcodeForm)
  )
)
