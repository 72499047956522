import {
  COUNT_RETAILERSGROUP_FAILED,
  COUNT_RETAILERSGROUP_SUCCEED,
  COUNT_RETAILERSGROUP_PROCESSING,
  FETCH_RETAILERSGROUP_FAILED,
  FETCH_RETAILERSGROUP_SUCCEED,
  FETCH_RETAILERSGROUP_PROCESSING,
  GET_RETAILERSGROUP_FAILED,
  GET_RETAILERSGROUP_SUCCEED,
  GET_RETAILERSGROUP_PROCESSING,
  FETCH_RETAILERSGROUP_REGIONS_FAILED,
  FETCH_RETAILERSGROUP_REGIONS_SUCCEED,
  FETCH_RETAILERSGROUP_REGIONS_PROCESSING,
  FETCH_RETAILERSGROUP_PRODUCTS_FAILED,
  FETCH_RETAILERSGROUP_PRODUCTS_SUCCEED,
  FETCH_RETAILERSGROUP_PRODUCTS_PROCESSING,
  SAVE_RETAILERSGROUP_FAILED,
  SAVE_RETAILERSGROUP_SUCCEED,
  SAVE_RETAILERSGROUP_PROCESSING,
  SAVE_RETAILERSGROUP_PRODUCTS_FAILED,
  SAVE_RETAILERSGROUP_PRODUCTS_SUCCEED,
  SAVE_RETAILERSGROUP_PRODUCTS_PROCESSING,
  DELETE_RETAILERSGROUP_FAILED,
  DELETE_RETAILERSGROUP_SUCCEED,
  DELETE_RETAILERSGROUP_PROCESSING,
} from '../../constants'
import Parse from '../parse'
import { isString } from 'lodash'

const parseObject = 'RetailersGroup'
const RetailersGroup = Parse.Object.extend(parseObject)
const Region = Parse.Object.extend('Region')

export const retailersGroup = {
  count(searchText) {
    return async dispatch => {
      dispatch({
        type: COUNT_RETAILERSGROUP_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend(parseObject))
      searchText = searchText || ''
      if (searchText !== '') {
        query.matches('retailersGroupName', searchText.trim(), 'i')
      }
      try {
        const total = await query.count({ useMasterKey: true })
        dispatch({
          type: COUNT_RETAILERSGROUP_SUCCEED,
          payload: {
            total: total,
          },
        })
      } catch (error) {
        dispatch({
          type: COUNT_RETAILERSGROUP_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
  list(page, rowsPerPage, searchText) {
    return async dispatch => {
      dispatch({
        type: FETCH_RETAILERSGROUP_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend(parseObject))
      searchText = searchText || ''
      if (searchText !== '') {
        query.matches('name', searchText.trim(), 'i')
      }
      let results = []
      try {
        results = await query
          .include('regions')
          .descending('updatedAt')
          .limit(rowsPerPage)
          .skip(page * rowsPerPage)
          .find()
      } catch (error) {
        return dispatch({
          type: FETCH_RETAILERSGROUP_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
      var list = []
      results.forEach(object => {
        // !isString(region) ? region.get('regionName') : region
        let regions = object.get('regions')
          ? object.get('regions').map(region => region)
          : []
        let logo = ''
        const retailersGroupLogo = object.get('logo')
        if (retailersGroupLogo) {
          logo = retailersGroupLogo.url()
        }
        list.push({
          [object.id]: {
            ...object.toJSON(),
            logo,
            regions: regions.join(', ').substr(0, 50) + '…',
          },
        })
      })
      dispatch({
        type: FETCH_RETAILERSGROUP_SUCCEED,
        payload: {
          list: list,
        },
      })
    }
  },
  async select(searchText, limit = 5) {
    searchText = searchText || ''
    let query = new Parse.Query(Parse.Object.extend(parseObject))
    if (searchText !== '') {
      query.matches('name', searchText.trim().toLowerCase(), 'i')
    }
    const results = await query.limit(limit).find()
    return results.map(result => ({
      value: result.id,
      label: result.get('name'),
    }))
  },
  get(id) {
    return async dispatch => {
      dispatch({
        type: GET_RETAILERSGROUP_PROCESSING,
      })
      const rGQuery = new Parse.Query(Parse.Object.extend(parseObject))
      const rGProductQuery = new Parse.Query(
        Parse.Object.extend('RetailersGroupProducts')
      )
      let retailersGroupProductsParse = []
      let rG = {}
      try {
        rG = await rGQuery.get(id)
        retailersGroupProductsParse = await rGProductQuery
          .equalTo('retailersGroup', rG)
          .include('products')
          .include('category')
          .find()
      } catch (error) {
        dispatch({
          type: GET_RETAILERSGROUP_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
      let retailersGroupProducts = {}
      retailersGroupProductsParse.forEach(rGProduct => {
        let category = ''
        const rGPCategory = rGProduct.get('category')
        if (rGPCategory) {
          category = rGPCategory.id
        }
        const rGProducts = rGProduct.get('products')
        if (rGProducts.length > 0) {
          rGProducts.forEach((rGProduct, i) => {
            if (rGProduct) {
              retailersGroupProducts[`cate-${category}-product${i + 1}`] = {
                value: rGProduct.id,
                label: rGProduct.get('productName'),
                category: category,
                record: rG.id,
              }
            }
          })
        }
      })
      let regions = []
      const rGRegions = rG.get('regions')
      if (rGRegions && rGRegions.length > 0) {
        regions = rGRegions
          .filter(r => !isString(r))
          .map(r => ({
            label: r.get('regionName'),
            value: r.id,
          }))
      }
      let logo = ''
      const retailersGroupLogo = rG.get('logo')
      if (retailersGroupLogo) {
        logo = retailersGroupLogo.url()
      }
      const record = {
        object_id: rG.id,
        logo,
        name: rG.get('name'),
        isDefault: rG.get('isDefault'),
        isPartner: rG.get('isPartner'),
        hasStripeSubscription: rG.get('hasStripeSubscription'),
        regions,
      }
      return dispatch({
        type: GET_RETAILERSGROUP_SUCCEED,
        payload: {
          record,
          retailersGroupProducts,
        },
      })
    }
  },
  getRegions(searchText) {
    return async dispatch => {
      dispatch({
        type: FETCH_RETAILERSGROUP_REGIONS_PROCESSING,
      })
      let query = new Parse.Query(Region)
      searchText = searchText || ''
      if (searchText !== '') {
        // query.fullText('retailersGroupName', searchText);
        query.matches('regionName', searchText.trim(), 'i')
      }
      query.limit(10000)
      let results = []
      try {
        results = await query.find()
      } catch (error) {
        return dispatch({
          type: FETCH_RETAILERSGROUP_REGIONS_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
      var list = []
      for (var i = 0; i < results.length; i++) {
        var object = results[i]
        list.push({
          value: object.id,
          label: object.get('regionName'),
        })
      }
      return dispatch({
        type: FETCH_RETAILERSGROUP_REGIONS_SUCCEED,
        payload: {
          regions: list,
        },
      })
    }
  },
  getProducts() {
    return async dispatch => {
      dispatch({
        type: FETCH_RETAILERSGROUP_PRODUCTS_PROCESSING,
      })
      let queryCategories = new Parse.Query(
        Parse.Object.extend('GenusCategory')
      )
      let resultCategories = []
      try {
        resultCategories = await queryCategories
          .limit(10000)
          .ascending('name')
          .find()
      } catch (error) {
        return dispatch({
          type: FETCH_RETAILERSGROUP_PRODUCTS_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
      var categories = resultCategories.map(resultCategory => {
        return {
          value: resultCategory.id,
          label: resultCategory.get('name'),
        }
      })
      const queryProducts = new Parse.Query(Parse.Object.extend('Products'))
      const resultProducts = await queryProducts
        .ascending('productName')
        .limit(1000)
        .find()
      var products = resultProducts.map(resultProduct => ({
        value: resultProduct.id,
        label: resultProduct.get('productName'),
      }))
      return dispatch({
        type: FETCH_RETAILERSGROUP_PRODUCTS_SUCCEED,
        payload: {
          products: products,
          categories: categories,
        },
      })
    }
  },
  save(values) {
    return async dispatch => {
      dispatch({
        type: SAVE_RETAILERSGROUP_PROCESSING,
      })
      let retailersGroup = {}
      if (values.object_id) {
        const retailersGroupQuery = new Parse.Query(RetailersGroup)
        try {
          retailersGroup = await retailersGroupQuery.get(values.object_id)
        } catch (err) {
          dispatch({
            type: SAVE_RETAILERSGROUP_FAILED,
            payload: {
              error: `Error: ${err.code} ${err.message}`,
            },
          })
        }
      } else {
        retailersGroup = new RetailersGroup()
      }
      retailersGroup.set({
        logo: values.logo,
        name: values.name,
        isDefault: Boolean(values.isDefault),
        isPartner: Boolean(values.isPartner),
        hasStripeSubscription: Boolean(values.hasStripeSubscription),
      })
      let regions = []
      if (values.regions.length > 0) {
        let regionQuery = new Parse.Query(Region)
        const parseRegions = await regionQuery
          .containedIn(
            'objectId',
            values.regions.map(r => r.id)
          )
          .find()
        regions = parseRegions.map(r => r.toPointer())
      }
      retailersGroup.set('regions', regions)
      if (values.logo) {
        let fileName = values.logo.name
        let parseFile = new Parse.File(fileName, values['logo'])
        try {
          await parseFile.save()
          // The file has been saved to Parse.
          retailersGroup.set('logo', parseFile)
        } catch (error) {
          dispatch({
            type: SAVE_RETAILERSGROUP_FAILED,
            payload: {
              error: `Error: ${error.code} ${error.message}`,
            },
          })
        }
      }
      // now save
      try {
        await retailersGroup.save()
        dispatch({
          type: SAVE_RETAILERSGROUP_SUCCEED,
        })
      } catch (error) {
        dispatch({
          type: SAVE_RETAILERSGROUP_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
  groupBy(xs, f) {
    return xs.reduce(
      (r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), // eslint-disable-line
      {}
    )
  },
  saveProducts(values) {
    return async dispatch => {
      dispatch({
        type: SAVE_RETAILERSGROUP_PRODUCTS_PROCESSING,
      })
      let data = Object.keys(values).map(k => {
        return values[k]
      })
      let groupData = this.groupBy(data, p => p.category)
      let dataToSave = []
      Object.values(groupData).forEach(async categoryData => {
        let products = categoryData
          .filter(p => p.value)
          .map(p => {
            let productModel = Parse.Object.extend('Products')
            let product = new productModel()
            product.id = p.value
            return product
          })

        const genusCategoryQuery = new Parse.Query(
          Parse.Object.extend('GenusCategory')
        )
        let category = await genusCategoryQuery.get(categoryData[0].category)
        const retailersGroupQuery = new Parse.Query(RetailersGroup)
        let retailersGroup = await retailersGroupQuery.get(
          categoryData[0].record
        )
        let retailersGroupTable = Parse.Object.extend('RetailersGroupProducts')
        let retailersGroupProduct = new retailersGroupTable()
        retailersGroupProduct
          .set('category', category.toPointer())
          .set('products', products)
          .set('retailersGroup', retailersGroup.toPointer())
        dataToSave.push(retailersGroupProduct)
      })
      try {
        await Parse.Object.saveAll(dataToSave)
        dispatch({
          type: SAVE_RETAILERSGROUP_PRODUCTS_SUCCEED,
        })
      } catch (error) {
        dispatch({
          type: SAVE_RETAILERSGROUP_PRODUCTS_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
  delete(ids) {
    return async dispatch => {
      dispatch({
        type: DELETE_RETAILERSGROUP_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend(parseObject))
      try {
        const retailersGroups = await query.containedIn('objectId', ids).find()
        await Parse.Object.destroyAll(retailersGroups)
        dispatch({
          type: DELETE_RETAILERSGROUP_SUCCEED,
        })
      } catch (error) {
        dispatch({
          type: DELETE_RETAILERSGROUP_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
}
