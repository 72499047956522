import React, { Component } from "react"
import { connect } from "react-redux"
import { Edit as EditIcon, Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons'
import { Button } from '@material-ui/core'
import debounce from 'lodash.debounce'

import masterActions from "../../actions/master.actions"
import CustomTable from "../Table/CustomTable"
import CustomSnackbar from 'components/CustomSnackbar'

class Master extends Component {
    state = {
        page: 0,
        rowsPerPage: 10,
        fields: [
            { id: 'sharedCredits', numeric: false, disablePadding: false, label: 'Shared Credits' },
            { id: 'signupCredits', numeric: false, disablePadding: false, label: 'Signup Credits' },
            { id: 'signupCreditsForSharedUser', numeric: false, disablePadding: false, label: 'Signup Credits For Shared User' },
            { id: 'gardenCompassSubscriptionLink', numeric: false, disablePadding: false, label: 'Garden Compass Subscription Link' },
            { id: 'gardenDesignLink', numeric: false, disablePadding: false, label: 'Garden Design Link' },
            { id: 'careCalendarLink', numeric: false, disablePadding: false, label: 'Care Calendar Link' },
        ],
    }
    async componentDidMount() {
        await this.props.count()
        await this._getMaster()
    }
    async _getMaster() {
        await this.props.fetch(this.state.page, this.state.rowsPerPage)
    }
    handleChangePage = page => {
        this.setState({ page: page }, async () => {
            await this._getMaster()
        })
    }
    handleSearch = debounce(async query => {
        await this.props.count(query)
        await this.props.fetch(this.state.page, this.state.rowsPerPage, query)
    }, 1000)
    handleChangeRowsPerPage = rowsPerPage => {
        this.setState({ rowsPerPage: rowsPerPage }, async () => {
            await this._getMaster()
        })
    }
    handleDeleteAction = async id => {
        await this.props.delete(id)
    }
    hideAlert(event) {
        this.props.hideAlert()
    }
    render() {
        const { deleted } = this.props
        return (
            <>
                <CustomSnackbar
                    variant="error"
                    open={deleted}
                    message={'Deleted'}
                    handleClose={() => this.hideAlert()}
                />
                <div style={{ textAlign: "right" }}>
                    <Button variant="contained" color="primary" onClick={() => this.props.history.push("/master/add")}>
                        <AddIcon /> Add New
                    </Button>
                </div>
                <CustomTable
                    total={this.props.total}
                    history={this.props.history}
                    tableHeaderColor="primary"
                    tableHead={this.state.fields}
                    page={this.state.page}
                    rowsPerPage={this.state.rowsPerPage}
                    handleChangePage={this.handleChangePage}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    handleSearch={this.handleSearch}
                    handleDelete={this.handleDeleteAction}
                    data={this.props.list}
                    editPath="/master/edit/"
                    actions={[
                        { label: "edit", icon: <EditIcon />, path: "/master/edit/", has_id: true, color: "primary" },
                        { label: "delete", icon: <DeleteIcon />, has_id: true, color: "secondary" }
                    ]}
                />
            </>
        )
    }

}

const mapStateToProps = state => ({
    total: state.master.total,
    list: state.master.list,
    deleted: state.master.deleted,
    errorMessage: state.master.errorMessage,
})

const mapDispatchToProps = {
    ...masterActions
}

export default connect(mapStateToProps, mapDispatchToProps)(Master)
