import React, { Component } from 'react'
import { connect } from 'react-redux'
import { category } from '../../actions'
import CustomTable from '../Table/CustomTable'
import {
  Edit as EditIcon,
  Add as AddIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons'
import { Button } from '@material-ui/core'
import debounce from 'lodash.debounce'
import CustomSnackbar from 'components/CustomSnackbar'

class Category extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      rowsPerPage: 10,
      fields: [
        {
          id: 'categoryName',
          label: 'Category Name',
        },
        {
          id: 'categoryDescription',
          label: 'Category Description',
        },
      ],
    }
  }
  componentDidMount() {
    this.props.dispatch(category.count())
    this._getCategory()
  }
  _getCategory() {
    this.props.dispatch(category.list(this.state.page, this.state.rowsPerPage))
  }
  handleChangePage = page => {
    let that = this
    this.setState({ page: page }, () => {
      that._getCategory()
    })
  }
  handleSearch = debounce(query => {
    this.props.dispatch(category.count(query))
    this.props.dispatch(
      category.list(this.state.page, this.state.rowsPerPage, query)
    )
  }, 1000)
  handleChangeRowsPerPage = rowsPerPage => {
    let that = this
    this.setState({ rowsPerPage: rowsPerPage }, () => {
      that._getCategory()
    })
  }
  handleDeleteAction = id => {
    this.props.dispatch(category.delete(id))
  }
  hideAlert(event) {
    this.props.dispatch(category.hideAlert())
  }
  render() {
    const { errorMessage } = this.props
    return (
      <div>
        <CustomSnackbar
          variant="error"
          open={Boolean(errorMessage)}
          message={errorMessage && errorMessage.message}
          handleClose={() => this.handleCloseError()}
        />
        <div style={{ textAlign: 'right' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => this.props.history.push('/categories/add')}
          >
            <AddIcon /> Add New
          </Button>
        </div>
        <CustomTable
          title="Category"
          total={this.props.total}
          history={this.props.history}
          tableHeaderColor="primary"
          tableHead={this.state.fields}
          page={this.state.page}
          rowsPerPage={this.state.rowsPerPage}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          handleSearch={this.handleSearch}
          handleDelete={this.handleDeleteAction}
          data={this.props.list}
          editPath="/categories/edit/"
          actions={[
            {
              label: 'edit',
              icon: <EditIcon />,
              path: '/categories/edit/',
              has_id: true,
              color: 'primary',
            },
            {
              label: 'delete',
              icon: <DeleteIcon />,
              has_id: true,
              color: 'secondary',
            },
          ]}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  total: state.category.total,
  list: state.category.list,
  deleted: state.category.deleted,
  errorMessage: state.category.errorMessage,
})

export default connect(mapStateToProps)(Category)
