import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { Button, CardActions } from '@material-ui/core'
import Select from 'react-select'
import SweetAlert from 'sweetalert2-react'

import RegularCard from '../Cards/RegularCard.js'
import { region as regionActions } from '../../actions'
import { HEMISPHERES } from '../../constants'
import CustomTextField from 'components/Input/CustomTextField.js'
import formPropTypes from '../../constants/formPropTypes'

// @TODO: Add loading spinner (see CategoryForm)

export const fields = [
  'object_id',
  'regionName',
  'hemisphere',
  'subscriptionCurrency',
]
const hemispheres = Object.keys(HEMISPHERES).map(key => {
  return { value: key, label: HEMISPHERES[key] }
})

class RegionForm extends Component {
  static propTypes = {
    ...formPropTypes,
    getSubscriptionCurrencies: PropTypes.func.isRequired,
    subscriptionCurrencies: PropTypes.array.isRequired,
  }

  submit(values) {
    values.object_id = this.props.initialValues.object_id
    this.props.save(values)
  }
  componentDidMount() {
    this.props.getSubscriptionCurrencies()
    this.props.get(this.props.match.params.id)
  }
  hideAlert() {
    this.props.hideAlert()
    this.props.history.push('/regions')
  }
  render() {
    const {
      record,
      subscriptionCurrencies,
      handleSubmit,
      saved,
      errorMessage,
    } = this.props
    const title =
      (this.props.match.params.id ? 'Edit ' : 'Add new') + record.regionName
    return (
      <div>
        <RegularCard
          cardTitle={title}
          content={
            <form onSubmit={handleSubmit(this.submit.bind(this))}>
              <div>
                <Field
                  name="regionName"
                  component={CustomTextField}
                  label="Region Name"
                />
              </div>

              <div>
                <label>hemisphere</label>
                <Field
                  name="hemisphere"
                  label="hemisphere"
                  component={props => (
                    <Select
                      {...props.input}
                      options={hemispheres}
                      placeholder="Select a hemisphere"
                    />
                  )}
                />
              </div>

              <div>
                <label>Subscription Currency</label>
                <Field
                  name="subscriptionCurrency"
                  label="subscriptionCurrency"
                  component={props => (
                    <Select
                      {...props.input}
                      options={subscriptionCurrencies}
                      placeholder="Select a subscription currency"
                      simpleValue
                    />
                  )}
                />
              </div>

              <CardActions>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={this.props.fetching}
                >
                  Submit
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => this.props.history.push('/regions')}
                >
                  Back to list
                </Button>
              </CardActions>
            </form>
          }
          footer={''}
        />
        <SweetAlert
          type={errorMessage ? 'error' : 'success'}
          show={saved || Boolean(errorMessage)}
          title={errorMessage ? 'Error' : 'Notice'}
          text={errorMessage ? errorMessage : 'Saved'}
          onConfirm={this.hideAlert.bind(this)}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ region }) => ({
  record: region.record,
  subscriptionCurrencies: region.subscriptionCurrencies,
  fetching: region.fetching,
  fetched: region.fetched,
  saving: region.saving,
  saved: region.saved,
  errorMessage: region.errorMessage,
  fields,
  initialValues: region.record,
})

const mapDispatchToProps = { ...regionActions }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'region',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(RegionForm)
)
