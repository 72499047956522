import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'

import {
  CardActions,
  Button,
  CircularProgress,
  Fade,
  withStyles,
} from '@material-ui/core'

import RegularCard from '../Cards/RegularCard.js'
import { connect } from 'react-redux'

import SweetAlert from 'sweetalert2-react'

import { retailer as retailerActions } from '../../actions'
import { overlay, loadingSpinner } from '../../variables/styles'
import CustomTextField from 'components/Input/CustomTextField.js'
import formPropTypes from '../../constants/formPropTypes'
import CustomMultiSelect from 'components/Input/CustomMultiSelect.js'

export const fields = [
  'object_id',
  'name',
  'products',
  'address',
  'address2',
  'city',
  'state',
  'country',
  'zip',
  'phone',
  'email',
  'fax',
  'url',
  'latitude',
  'longitude',
]

class RetailerForm extends Component {
  static propTypes = {
    ...formPropTypes,
  }

  submit(values) {
    values.object_id = this.props.initialValues.object_id
    values.oldProducts =
      this.props.retailerProducts &&
      this.props.retailerProducts.length > 0 &&
      this.props.retailerProducts[0] !== null
        ? this.props.retailerProducts.map(product => product.id)
        : []
    this.props.save(values)
  }
  componentDidMount() {
    this.props.getProducts()
    if (this.props.match.params.id) {
      this.props.get(this.props.match.params.id)
    }
  }
  hideAlert() {
    this.props.hideAlert()
    this.props.history.push('/retailers')
  }
  render() {
    const { record, classes, products, handleSubmit, saved, error } = this.props
    const title =
      (this.props.match.params.id ? 'Edit ' : 'Add new') + record.name
    return (
      <div>
        <Fade in={this.props.fetching}>
          <div
            className={classes.overlay}
            style={{ zIndex: this.props.fetching ? 10 : 0 }}
          >
            <CircularProgress className={classes.loadingSpinner} />
          </div>
        </Fade>
        <RegularCard
          cardTitle={title}
          content={
            <form onSubmit={handleSubmit(this.submit.bind(this))}>
              <div>
                <Field
                  name="name"
                  component={CustomTextField}
                  label="Retailer Name"
                />
              </div>
              <div>
                <Field
                  name="products"
                  label="Products"
                  options={products}
                  component={CustomMultiSelect}
                />
              </div>
              <div>
                <Field
                  name="address"
                  component={CustomTextField}
                  label="Address 1"
                />
              </div>
              <div>
                <Field
                  name="address2"
                  component={CustomTextField}
                  label="Address 2"
                />
              </div>
              <div>
                <Field name="city" component={CustomTextField} label="City" />
              </div>
              <div>
                <Field name="state" component={CustomTextField} label="State" />
              </div>
              <div>
                <Field
                  name="country"
                  component={CustomTextField}
                  label="Country"
                />
              </div>
              <div>
                <Field
                  name="zip"
                  component={CustomTextField}
                  label="Zip code"
                />
              </div>
              <div>
                <Field name="phone" component={CustomTextField} label="Phone" />
              </div>
              <div>
                <Field name="email" component={CustomTextField} label="Email" />
              </div>
              <div>
                <Field name="fax" component={CustomTextField} label="Fax" />
              </div>
              <div>
                <Field name="url" component={CustomTextField} label="Url" />
              </div>
              <div>
                <Field
                  name="latitude"
                  component={CustomTextField}
                  label="Latitude"
                />
              </div>
              <div>
                <Field
                  name="longitude"
                  component={CustomTextField}
                  label="Longitude"
                />
              </div>

              <CardActions>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={this.props.fetching}
                >
                  Submit
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => this.props.history.push('/categories')}
                >
                  Back to list
                </Button>
              </CardActions>
            </form>
          }
          footer={''}
        />
        <SweetAlert
          show={saved}
          title="Notice"
          text={error ? error.message : 'Saved'}
          onConfirm={this.hideAlert.bind(this)}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ retailer }) => ({
  record: retailer.record,
  products: retailer.products,
  fetching: retailer.fetching,
  fetched: retailer.fetched,
  saving: retailer.saving,
  saved: retailer.saved,
  retailerProducts: retailer.retailerProducts,
  fields,
  initialValues: retailer.record,
})

const styles = () => ({
  overlay,
  loadingSpinner,
})

const mapDispatchToProps = {
  ...retailerActions,
}

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      // a unique name for the form
      form: 'retailer',
      enableReinitialize: true,
      keepDirtyOnReinitialize: true,
    })(RetailerForm)
  )
)
