import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { TextField, Button, CardActions } from '@material-ui/core'

import RegularCard from '../Cards/RegularCard.js'
import masterActions from '../../actions/master.actions'
import CustomSnackbar from 'components/CustomSnackbar/index.js'

export const fields = [
  'object_id',
  'masterName',
  'categoryType',
  'commonName',
  'masterProduct',
]

const renderInput = ({
  meta: { touched, error } = {},
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    helperText={touched ? error : ''}
    error={touched ? (error === undefined ? false : true) : false}
    {...inputProps}
    {...props}
    fullWidth
  />
)

const onSubmit = async (values, dispatch, props) => {
  try {
    values.object_id = this.props.initialValues.object_id
    await props.save(values)
  } catch (err) {
    throw new SubmissionError({ _error: 'Saving master failed' })
  }
}

class GenusForm extends Component {
  async componentDidMount() {
    await this.props.get(this.props.match.params.id)
  }

  handleCloseError = () => {
    this.props.hideAlert()
  }

  hideAlert = () => {
    this.props.reset()
  }

  render() {
    const { record, handleSubmit, saved, error } = this.props
    const title =
      (this.props.match.params.id ? 'Edit ' : 'Add new') +
      (record !== null && record.masterName ? record.masterName : '')

    return (
      <div>
        <CustomSnackbar
          variant="error"
          open={error}
          message={error && error.message}
          handleClose={() => this.handleCloseError()}
        />
        <CustomSnackbar
          variant="success"
          open={saved}
          message={'Saved'}
          autoHideDuration={1000}
          onClose={() => this.hideAlert()}
        />
        <RegularCard
          cardTitle={title}
          content={
            <form onSubmit={handleSubmit}>
              <div>
                <Field
                  name="sharedCredits"
                  component={renderInput}
                  label="Shared Credits"
                />
              </div>

              <div>
                <Field
                  name="signupCredits"
                  component={renderInput}
                  label="Sign-up Credits"
                />
              </div>
              <div>
                <Field
                  name="signupCreditsForSharedUser"
                  component={renderInput}
                  label="Sign-up Credits For SharedUser"
                />
              </div>
              <div>
                <Field
                  name="GCHomePageLink"
                  component={renderInput}
                  label="GC Home Page Link"
                />
              </div>

              <div>
                <Field
                  name="GardenDesignLink"
                  component={renderInput}
                  label="Garden Design Link"
                />
              </div>
              <div>
                <Field
                  name="CareCalendarLink"
                  component={renderInput}
                  label="Care Calendar Link"
                />
              </div>
              <div>
                <Field
                  name="PSHomePageLink"
                  component={renderInput}
                  label="PS Home Page Link"
                />
              </div>

              <div>
                <Field
                  name="PSGardenSmarterLink"
                  component={renderInput}
                  label="PS Garden Smarter Link"
                />
              </div>
              <div>
                <Field
                  name="GCGardenSmarterLink"
                  component={renderInput}
                  label="GC Garden Smarter Link"
                />
              </div>
              <div>
                <Field
                  name="localRetailerFilterDistance"
                  component={renderInput}
                  label="Local Retailer Filter Distance"
                />
              </div>

              <CardActions>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={this.props.fetching}
                >
                  Submit
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => this.props.history.push('/master')}
                >
                  Back to list
                </Button>
              </CardActions>
            </form>
          }
          footer={''}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ master }) => ({
  record: master.record,
  fetching: master.fetching,
  fetched: master.fetched,
  saving: master.saving,
  saved: master.saved,
  fields,
  initialValues: master.record,
})

const mapDispatchToProps = {
  ...masterActions,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'master',
    onSubmit,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(GenusForm)
)
